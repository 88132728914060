@import "variables";
@import "bootstrap";
@import "svgs";
@import '../../node_modules/custom-select/build/custom-select.css';
@import '../../node_modules/vanillajs-datepicker/sass/datepicker';

@import '../../node_modules/flatpickr/dist/themes/material_blue.css';

* {
  font-family: 'Montserrat', sans-serif, sans-serif;
}


@function str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }
  @return $string;
}

button {
  background: none;
  border: none;
}

%bold-heading {
  font-style: normal;
  font-weight: 600;
}

%text-regular {
  font-style: normal;
  font-weight: 400;
}

%btn__default {
  display: flex;
  width: 100%;
  max-height: 40px;
  min-height: 40px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  transition: all .3s;
}

%one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

p {
  padding: 0;
  margin: 0;
}

.h1 {
  @extend %bold-heading;
  font-size: 1.95em;
  line-height: 2.67em;
}

.h2 {
  @extend %bold-heading;
  font-size: 1.67em;
  line-height: 133%;
}

.h3 {
  @extend %bold-heading;
  font-size: 1.33em;
  line-height: 1.78em;
}

.h3-5 {
  @extend %text-regular;
  font-size: 1.33em;
  line-height: 1.55em;
}

.h4 {
  @extend %bold-heading;
  font-size: 1.11em;
  line-height: 1.55em;
}

.h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 1em;
  line-height: 1.33em;
}

a {
  text-decoration: none;
  cursor: pointer;
}

.text-1 {
  @extend %text-regular;
  font-size: 1em;
  line-height: 1.55em;
}

.text-2 {
  @extend %text-regular;
  font-size: .83em;
  line-height: 160%;
}

.text-1-bold {
  @extend %bold-heading;
  font-size: 1em;
  line-height: 1.55em;
}

.text-2-bold {
  @extend %bold-heading;
  font-size: .83em;
  line-height: 160%;

  &.phone_number {
    font-size: 14px;
  }
}

.common__link {
  @extend %text-regular;
  color: $--ui-color-blue;
  text-decoration: none;
}

@import './components/spacer';

$encoding-reference: (
                ('<', '%3C'),
                ('>', '%3E'),
                ('"', '\''),
                ('#', '%23'),
                ('&', '%26')
);

@function svg-encode($svg) {
  @each $char, $encoded in $encoding-reference {
    $svg: str-replace($svg, $char, $encoded);
  }
  @return 'data:image/svg+xml,' + $svg;
}

%centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

html {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  font-size: 18px;
  min-width: 1020px !important;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}


.auth__screen {
  @extend %centered-content;
  min-height: 100vh;
  height: 100%;
  //width: 100vw;
  min-width: 1020px;
  margin: auto 0;
  background: $--ui-color-inversive-400 url(svg-encode($--ui-logo-bg)) no-repeat center 309px;

  .centered__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .tittle__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 80px;
      width: 280px;

      .title {
        margin-top: 1.11em;
        color: $--ui-color-gray-dark;
      }

      .logo__wrapper {
        width: 80px;
        height: 60px;
      }
    }
  }
}

.modal__wrapper {
  @extend %centered-content;
  flex-direction: column;
  position: relative;
  max-width: 450px;
  border-radius: 30px;
  background: $--ui-color-inversive-500;
  box-shadow: 0 15px 50px rgba(80, 134, 224, 0.2);
  padding: 56px;

  .modal__paragraph {
    max-width: 340px;
    text-align: center;
    color: $--ui-color-500;
  }

  .input {
    &__wrapper {
      display: flex;
      flex-direction: column;
      width: 340px;
      position: relative;

      .password-icon {
        position: absolute;
        right: 22px;
        top: 32px;
        height: 24px;
        width: 24px;

        &.icon-hide {
          background: url(svg-encode($--ui-view-hide)) no-repeat center center;
        }

        &.icon-show {
          background: url(svg-encode($--ui-view-show)) no-repeat center center;
        }
      }
    }

    &__error {
      .input__default {
        border-color: $--ui-color-red;
      }

      .input__error-hint {
        @extend %text-regular;
        margin-top: 6px;
        color: $--ui-color-red;
        font-size: 0.72em;
        line-height: 154%;
      }
    }
  }

  .label__default {
    @extend %bold-heading;
    font-size: 11px;
    line-height: 13px;
    color: $--ui-color-300;
    letter-spacing: .05em;
    text-transform: uppercase;
    width: 100%;
  }

  .input__default {
    @extend %text-regular;
    padding: 6px 12px;
    border: 1px solid $--ui-color-gray;
    border-radius: 8px;
    margin-top: 12px;
    height: 40px;
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    max-width: 340px;
    transition: all .3s;
  }

  & :focus-visible {
    outline: none;
  }
}

.modal__wrapper__last {
  padding-top: 32px;
  width: 100%;
}

.btn__default {
  &-primary {
    @extend %btn__default;
    background-color: $--ui-color-blue;
    min-height: 48px;
    max-height: 48px;

    &-rounded {
      @extend %btn__default;
      background-color: $--ui-color-blue;
      border-radius: 39px;
      padding: 12px 24px;
      width: auto;
      min-height: 48px;
      max-height: 48px;

      &:hover {
        background-color: $--ui-color-blue-dark;
      }
    }

    &:hover {
      background-color: $--ui-color-blue-dark;
    }
  }

  &-white {
    @extend %btn__default;
    background-color: #4482FF;
    min-height: 48px;
    max-height: 48px;

    &-rounded {
      @extend %btn__default;
      border: 1px solid $--ui-color-blue-light;
      background-color: #FFFFFF;
      border-radius: 39px;
      padding: 12px 24px;
      width: auto;
      min-height: 48px;
      max-height: 48px;

      .btn__default-text {
        color: #4482FF;

        &:hover {
          color: $--ui-color-blue;
        }
      }
    }

  }

  &-light {
    @extend %btn__default;
    background-color: $--ui-color-blue-light;
    min-height: 48px;
    max-height: 48px;

    &-rounded {
      @extend %btn__default;
      background-color: $--ui-color-blue-light;
      border-radius: 39px;
      padding: 12px 24px;
      width: auto;
      min-height: 48px;
      max-height: 48px;

      .btn__default-text {
        color: $--ui-color-blue;

        &:hover {
          color: $--ui-color-blue;
        }
      }
    }

    .btn__default-text {
      color: $--ui-color-blue;

      &:hover {
        color: $--ui-color-blue;
      }
    }

    &:hover {
      background-color: #E1E8F6;
    }
  }

  &-outline {
    @extend %btn__default;
    border: 1px solid $--ui-color-blue-light;
    min-height: 48px;
    max-height: 48px;
    padding: 12px 24px;

    &-rounded {
      @extend %btn__default;
      border: 1px solid $--ui-color-blue-light;
      border-radius: 39px;
      padding: 12px 24px;
      width: auto;
      min-height: 48px;
      max-height: 48px;

      .btn__default-text {
        color: $--ui-color-blue;

        &:hover {
          color: $--ui-color-blue;
        }
      }
    }

    .btn__default-text {
      color: $--ui-color-blue;

      &:hover {
        color: $--ui-color-blue;
      }
    }

    &:hover {
      background-color: #F8FAFE;
    }
  }

  &-text {
    @extend %bold-heading;
    font-size: 0.7em;
    line-height: 160%;
    color: $--ui-color-inversive-500;
    text-decoration: none;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    .btn-icon-left {
      margin-right: 8px;
    }

    .btn-icon-right {
      margin-left: 8px;
    }

    &:hover {
      color: $--ui-color-inversive-500;
    }
  }
}

.code-problem-hint {
  flex: 1;
}

li {
  list-style-type: none;
  position: relative;
}

ul {
  margin-bottom: 0;
}

li {
  &.rule-succeed {
    color: #3AC267;

    &:before {
      content: "✓";
      font-size: 1em;
    }
  }

  &:before {
    content: url(svg-encode($item-circle));
    width: 12px;
    height: 12px;
    position: absolute;
    left: -18px;
    top: 6px;
  }
}

//
//li


.test__modal {
  height: 500px;
  width: 450px;
}

.text-secondary-color {
  color: $--ui-color-400;
}

.checkbox__wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;

  & .input__error-hint {
    @extend %text-regular;
    margin-left: 32px;
    color: #DF2059;
    font-size: 13px;
    line-height: 20px;
  }

  &-disabled {
    opacity: .5;

    & .checkbox_input {
      width: 24px;
      height: 24px;
      background: url(svg-encode($--ui-uncheck-square-disabled));

      &-active {
        width: 24px;
        height: 24px;
        background: url(svg-encode($--ui-check-square-disabled));
      }
    }
  }

  &-error {

  }

  & .checkbox_input {
    width: 24px;
    height: 24px;
    margin-right: 10px;
    display: flex;
    background: url(svg-encode($--ui-uncheck-square-active));

    &-active {
      width: 24px;
      height: 24px;
      background: url(svg-encode($--ui-check-square-active));
    }
  }

  & .checkbox_label {

  }
}

.modal__loader_blur-enabled {
  background: rgba(149, 166, 195, 0.2);
    backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .loader {
    border: 5.5px solid #B2CEFF;
    border-top: 5.5px solid #005BFF;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.modal__loader-enabled {
  background: rgba(255, 255, 255, .7);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  .loader {
    border: 5.5px solid #B2CEFF;
    border-top: 5.5px solid #005BFF;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.email-hint {
  margin-top: 6px;
  font-size: 13px;
  line-height: 20px;
}

.header__wrapper {
  height: 80px;
  background: #F5F7FA;

  & .common__link {
    @extend %bold-heading;
    font-size: 13px;
    line-height: 20px;
  }

  & .logo__header {
    height: 32px;
    width: 42px;
    margin-right: 12px;
  }

  & .header__title {
    font-size: 13px;
    line-height: 20px;
  }

  & .right_section__header {
    & .icon__wrapper {
      margin: auto 12px;
    }

    & .icon__wrapper:last-child {
      margin: auto;
    }

    .user-menu__wrapper {
      padding: 0;
    }

    .notification-button {
      padding: 0;
    }

    & .vertical-divider {
      min-height: 36px;
      margin-right: 12px;
    }
  }
}

.vertical-divider {
  height: 100%;
  max-height: 36px;
  border-left: 0.5px solid $--ui-color-gray;
}

.horizontal-divider {
  width: 100%;
  border-bottom: 0.5px solid $--ui-color-gray;
}

.icon__wrapper {
  min-height: 24px;
  max-height: 24px;
  min-width: 24px;
  max-width: 24px;
}

.chips__container {
  .chips-item {
    padding: 0 16px;

    &:first-child {
      padding-left: 0;
    }

    &last-child {
      padding-right: 0;
    }

    .common__link {
      @extend %bold-heading;
    }

    & .icon__wrapper {
      margin-right: 8px;
    }
  }
}

.tab-menu__container {
  //overflow-x: scroll;
  /*.dropdown {
    cursor: pointer;
  }
  .dropdown-toggle:after {
    border: 0;
    width: 12px;
    height: 6px;
    margin-right: 8px;
    vertical-align: 2px;
    background: url(svg-encode($--ui-arrow-down)) no-repeat center center;
  }*/
  .btn__default-primary-rounded {
    padding: 12px 24px;
  }

  .dropdown-item {
    color: rgb(141, 149, 165);

    &.dropdown-active {
      color: $--ui-color-blue;
      border-left: 2px solid $--ui-color-blue;
      background-color: $--ui-color-blue-bg;
    }
  }

  & .tab-item__wrapper {
    padding: 0 16px;
    max-width: 256px;

    a {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    & .tab-item__container {
      &.tab-item__add {
        & a.text-2-bold {
          min-width: 165px;
          color: $--ui-color-blue;
        }
      }

      padding: 8px;

      &.tab-menu-active {
        color: $--ui-color-blue;
        border-bottom: 2px solid $--ui-color-blue;

        & a {
          color: $--ui-color-blue;
          text-decoration: none;
        }
      }
    }

    &:last-child {
      border-right: none;
    }

    & .common__link {
      font-size: 15px;
      line-height: 24px;
      @extend %bold-heading;
    }

    & .text-2-bold {
      @extend %one-line;
      white-space: normal;
      font-size: 15px;
      line-height: 24px;
      color: rgba(141, 149, 165, 1);
      text-decoration: none;
    }

    & .icon__wrapper {
      margin-right: 8px;
    }
  }
}

.alert {
  padding: 12px 24px;
  border-radius: 8px;
  border: none;

  & .alert-body {
    flex: 1;
  }

  & .icon__wrapper {
    margin-right: 16px;
  }

  & .icon__close {
    margin-right: 0;
    //margin-left: 10px;
  }

  .text-2 {
    color: $--ui-color-500;
  }

  .common__link {
    margin-right: 10px;
  }

  & .alert-action__section {
    & .common__link {
      font-size: 15px;
      line-height: 24px;
    }
  }
}

.alert-warning {
  background-color: rgba(255, 247, 209, 0.3);

  & .icon__wrapper.icon {
    background: url(svg-encode($warning-icon)) no-repeat center center;
  }
}

.alert-success {
  background-color: rgba(223, 250, 232, 0.3);

  & .icon__wrapper.icon {
    background: url(svg-encode($success-icon)) no-repeat center center;
  }
}

.alert-error {
  background-color: rgba(253, 229, 236, 0.3);

  & .icon__wrapper.icon {
    background: url(svg-encode($error-icon)) no-repeat center center;
  }
}

.alert-info {
  background-color: rgba(233, 240, 253, 0.3);

  & .icon__wrapper.icon {
    background: url(svg-encode($info-icon)) no-repeat center center;
  }
}

.card__container {
  //overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.card__wrapper {
  min-width: 307px;
  max-width: 35%;
  width: auto;
  margin-right: 24px;
  transition: .4s max-width;
  position: relative;

  &:last-child {
    padding-right: 0;
  }
}

.acquiring__wrapper {
  min-width: 195px;
  max-width: 195px;
}

.card__item {
  min-height: 185px;
  //height: 185px;
  width: 100%;
  padding: 24px 30px;
  background-color: $--ui-color-blue-light;
  border-radius: 30px;
  flex: 1;
  border: 2px solid $--ui-color-blue-light;

  .month-flow__container {
    margin-left: 5px;
  }

  .card-detail {
    margin: 8px 0 16px 0;
  }

  .card-detail-active-user {
    margin-top: 7px;
  }

  & .card__header {
    margin-bottom: 16px;
    width: 100%;

    & .wallet {
      font-size: 13px;
      line-height: 20px;
      color: $--ui-color-500;
    }

    & .wallet_info {
      font-size: 13px;
      line-height: 20px;
      color: $--ui-color-blue;
    }
  }

  & .current__value {
    font-size: 30px;
    line-height: 40px;
    color: $--ui-color-500;

    &.big-num {
      font-size: 20px;
      line-height: 40px;
    }
  }

  & .card__body {

    & .card-status__container {
      color: $--ui-color-green;

      & .outcome__wrapper {
        color: black;
        text-align: right;

        & .outcome__value {
          color: $--ui-color-red;
        }
      }
    }

    & .income-outcome-bar {
      margin-top: 10px;
      background-color: $--ui-color-red;
      height: 5px;
      border-radius: 10px;

      & .income-ratio {
        height: 5px;
        border-radius: 10px;
        background-color: $--ui-color-green;
      }
    }
  }
}

.icon_download {
  background: url(svg-encode($download_icon)) no-repeat center center;
}

.card-add__item {
  & .card__body {
    align-items: center;
    justify-content: center;

    &:hover {
      & .card-add__paragraph {
        color: $--ui-color-blue-dark;
      }
    }

    & .card-add__paragraph {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.05em;
      text-align: center;
      text-transform: uppercase;
    }
  }
}

#no_code {
  cursor: pointer;
}

.icon-double-wallet {
  width: 44px;
  height: 44px;
  margin-bottom: 20px;
  object-fit: contain;
}

.operations__container {
  background-color: $--ui-color-inversive-500;
  box-shadow: 0 15px 50px 0 rgba(80, 134, 224, 0.2);
  border-radius: 30px;
  margin-bottom: 92px;

  & .operations__wrapper {
    & .operations__header {
      padding: 48px 60px 24px 60px;
      @extend %bold-heading;
      font-size: 35px;
      line-height: 50px;
      color: $--ui-color-500;
    }

    & .no-operations-btn__wrapper {
      width: 125px;
    }

    & .no-operations__text {
      font-size: 15px;
      line-height: 24px;
      color: $--ui-color-300;
    }

    .no_operations__item {
      margin-bottom: 100px;
    }
  }

  & .overall-info__wrapper {
    border-left: 1px solid rgba(146, 180, 246, 0.3);

    & .income__wrapper {
      height: 172px;
      padding: 0 40px;
      border-bottom: 1px solid rgba(146, 180, 246, 0.3);

      & .income__value {
        color: $--ui-color-green;
      }
    }

    & .outcome__wrapper {
      height: 172px;
      padding: 0 40px;
      border-bottom: 1px solid rgba(146, 180, 246, 0.3);

      & .outcome__value {
        color: $--ui-color-red;
      }
    }
  }
}

.icon__wrapper.dropdown {
  max-height: 6px;
  min-height: 6px;
  max-width: 10px;
  min-width: 10px;
  margin-left: 4px;
}

.operations__block {
  padding: 0 60px 24px 60px;

  .operation-month {
    padding: 23px 0 20px 0;
  }

  .operation-month-sum {
    .text-1:first-child {
      margin-right: 16px;
    }

    .income__value {
      color: $--ui-color-green;
    }

    .outcome__value {
      color: $--ui-color-red;
    }
  }

  .operation__item {
    padding: 23px 32px 20px 32px;
    border-bottom: 1px solid rgba(146, 180, 246, 0.3);

    &.operation__item_commission {
      border-bottom: 0px;

      & .operation-group-stick {
        width: 1px;
        height: 35px;
        position: absolute;
        left: 11.5px;
        bottom: -23px;
        background: rgba(146, 180, 246, 0.3);
      }
    }

    &.operation__item_operation {
      & .operation-group-stick {
        width: 1px;
        height: 35px;
        position: absolute;
        left: 11.5px;
        top: -23px;
        background: rgba(146, 180, 246, 0.3);
      }
    }

    & .operation-left__section {
      position: relative;

    }
  }

  .icon__wrapper {
    margin-right: 4px;
  }

  .text-2 {
    color: #8D95A5;
  }

  & .operation_negative {
    color: #172133;
  }

  & .operation-type {
    color: $--ui-color-500;
  }

  .operation-right__section {
    min-width: 200px;
  }
}

.operation__item {
  .icon__wrapper {
    margin-right: 20px;
  }

  &.operation__success {
    .icon__wrapper {
      background: url(svg-encode($success-icon)) no-repeat center center;
    }
  }

  &.operation__error {
    background: rgba(253, 229, 236, 0.3);

    .icon__wrapper {
      background: url(svg-encode($error-icon)) no-repeat center center;
    }
  }

  &.operation__waiting {
    background: rgba(230, 233, 240, 0.35);

    .icon__wrapper {
      background: url(svg-encode($icon-waiting)) no-repeat center center;
    }
  }

  &.operation__danger {
    background: rgba(253, 229, 236, 0.3);

    .icon__wrapper {
      background: url(svg-encode($error-icon)) no-repeat center center;
    }
  }

  &.operation__warning {
    background: rgba(255, 247, 209, 0.3);

    .icon__wrapper {
      background: url(svg-encode($warning-icon)) no-repeat center center;
    }
  }
}


.operations__wrapper {

  .pagination__container {
    margin-left: 60px;
  }
}

.pagination__container {
  * {
    margin: 0 12px;

    &.paginator__active {
      margin: 0
    }

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .icon__wrapper {
    display: flex;
    min-height: 12px;
    max-height: 12px;
    min-width: 12px;
    max-width: 12px;
  }

  .text-2-bold {
    color: $--ui-color-400;
  }

  .paginator__active {
    text-align: center;
    padding: 4px 4px;
    border-radius: 8px;
    min-width: 35px;
    border: 2px solid $--ui-color-blue;
  }
}

.rotate {
  &-90 {
    transform: rotate(90deg);
  }

  &-180 {
    transform: rotate(180deg);
  }

  &-270 {
    transform: rotate(270deg);
  }
}

@include media-breakpoint-down(lg) {
  .operations__block {
    padding: 0 60px;
  }
}

.blur-portal {
  display: none;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background: rgba(149, 166, 195, 0);
  backdrop-filter: blur(0px);
  box-shadow: none;
  transition: .3s all ease-in-out;
  opacity: 0;
  min-width: 1020px;

  &.active {
    display: flex;
  }

  &.animate {
    opacity: 1;
    background: rgba(149, 166, 195, 0.2);
    backdrop-filter: blur(10px);
  }
}

.blur-modal {
  background-color: white;
  z-index: 1000;
  border-radius: 30px;
  padding: 56px;
  box-shadow: 0 15px 50px 0 #5086E033;
  position: relative;

  .modal__close {
    position: absolute;
    top: 32px;
    right: 32px;
    height: 48px;
    width: 48px;
    border-radius: 24px;
    border: 1px solid $--ui-color-blue-light;
  }
}

.title-card__holder {
  position: absolute;
  top: 24px;
  right: 30px;
}

.card__input {
  position: relative;
  padding: 24px 30px;
  background-color: $--ui-color-blue-light;
  border-radius: 30px;
  max-width: 307px;
  width: 100%;
}


//----------------------
// Icon Container
//----------------------

.icon__container {
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  display: flex;

  &.warning-icon {
    background: url(svg-encode($warning-icon));
    background-size: contain;
  }

  &.success-icon {
    background: url(svg-encode($success-icon));
    background-size: contain;
  }

  &.error-icon {
    background: url(svg-encode($error-icon));
    background-size: contain;
  }

  &.red_warning-icon {
    background: url(svg-encode($red_warning));
    background-size: contain;
  }
}


.card-logo__wrapper {
  height: 10px;
  object-fit: contain;

  &.mir {
    height: 10px;
  }

  &.visa {
    height: 10px;
  }

  &.mastercard {
    width: 16px;
  }
}

.inline-input__label {
  @extend %bold-heading;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.05em;
  color: $--ui-color-300;
  text-transform: uppercase;
}

.mr-32 {
  margin-right: 32px;
}

.card__body {
  .inline-input__wrapper {
    width: 50%;
  }
}

.inline-input {
  border: 0;
  padding: 8px 0;
  border-bottom: 1px solid #8D95A5;
  background-color: rgba(0, 0, 0, 0);

  &:focus-visible {
    outline: none;
  }
}

.fade {
  transition: opacity 0.5s linear !important;
}

.toast-black-bg {
  background-color: #48556E;
  border-radius: 8px;
  padding: 12px 16px;
  color: $--ui-color-inversive-500;
}

.toast-white-bg {
  border: 0;
  background-color: $--ui-color-inversive-500;
  border-radius: 8px;
  padding: 12px 16px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
}

.toast .icon__wrapper {
  margin-right: 16px;
}

.no-border {
  border: none;
}

.profile__screen,
.replenishment__screen {
  color: $--ui-color-500;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  & .h3 {
    margin: 8px 0 0;
  }
}

.profile__screen,
.transfer__screen,
.replenishment__screen {
  .h5 {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  .h3 {
    font-size: 24px;
    line-height: 32px;
  }
}

.back_button {
  margin-bottom: 16px;

  & .icon__wrapper {
    margin-right: 8px;
  }

  .body-15-bold.common__link {
    @extend %bold-heading;
  }
}

/*.auth-item {
  margin-right: 24px;
}*/

.auth-property {
  @extend %bold-heading;
  font-size: 11px;
  line-height: 13px;
  color: $--ui-color-300;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.auth-value {
  @extend %text-regular;
  font-size: 18px;
  line-height: 28px;
  color: $--ui-color-500;
  overflow: hidden;
  text-overflow: ellipsis;
}

.additional__hint {
  padding-top: 8px;
  @extend %text-regular;
  font-size: 13px;
  line-height: 20px;
  color: $--ui-color-400;
}

.inline-input {
  color: $--ui-color-500;
  @extend %text-regular;
  font-size: 15px;
  line-height: 24px;
}

.datepicker {
  @extend %text-regular;
  font-size: 15px;
  line-height: 24px;

  & .days-of-week {
    display: none;
  }
}


.datepicker-cell.selected, .datepicker-cell.selected {
  background-color: $--ui-color-blue;
}

.datepicker-input.in-edit:focus, .datepicker-input.in-edit:active {
  box-shadow: none;
}

.inline-input:focus {
  outline: none;
}

.datepicker-input.in-edit {
  border-color: rgba(0, 0, 0, 0);
}

.profile__screen .profile__body {
  padding-bottom: 60px;

  .auth-parameters .auth-item:first-child {
    margin-right: 24px;
  }
}

.datepicker-dropdown .datepicker-picker {
  box-shadow: 0 5px 10px 0 rgb(80 134 224 / 10%);
  border: 1px solid $--ui-color-blue-light;
  border-radius: 16px;
  overflow: hidden;
  padding: 4px;
}

.inline-input__wrapper {
  position: relative;
}

.inline-input__wrapper .icon__wrapper {
  position: absolute;
  right: 0;
  top: 24px;
}

.body {
  &-13 {
    @extend %text-regular;
    font-size: 13px;
    line-height: 20px;

    &-bold {
      @extend %bold-heading;
      font-size: 13px;
      line-height: 20px;
    }
  }

  &-11 {
    @extend %text-regular;
    font-size: 11px;
    line-height: 13px;

    &-bold {
      @extend %bold-heading;
      font-size: 11px;
      line-height: 13px;
    }
  }

  &-15 {
    @extend %text-regular;
    font-size: 15px;
    line-height: 24px;

    &-bold {
      @extend %bold-heading;
      font-size: 15px;
      line-height: 24px;
    }
  }

  &-18 {
    @extend %text-regular;
    font-size: 18px;
    line-height: 28px;

    &-bold {
      @extend %bold-heading;
      font-size: 18px;
      line-height: 28px;
    }
  }
}

.caps {
  &-11 {
    @extend %bold-heading;
    text-transform: uppercase;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.05em;
  }

  &-13 {
    @extend %bold-heading;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.05em;
  }
}


.color {
  &-300 {
    color: $--ui-color-300;

    &:hover {
      color: $--ui-color-300;
    }
  }

  &-400 {
    color: $--ui-color-400;

    &:hover {
      color: $--ui-color-400;
    }
  }

  &-500 {
    color: $--ui-color-500;

    &:hover {
      color: $--ui-color-500;
    }
  }

  &-red {
    color: $--ui-color-red;

    &-important {
      color: $--ui-color-red !important;
    }

    &:hover {
      color: $--ui-color-red;
    }
  }

  &-green {
    color: $--ui-color-green;

    &:hover {
      color: $--ui-color-green;
    }

    &-dark {
      color: $--ui-color-green-dark;

      &:hover {
        color: $--ui-color-green-dark;
      }
    }
  }

  &-white {
    color: white;

    &:hover {
      color: white;
    }
  }

  &-blue {
    color: $--ui-color-blue;

    &:hover {
      color: $--ui-color-blue;
    }
  }

  &-grey-med {
    color: $--ui-color-grey-med;

    &:hover {
      color: $--ui-color-grey-med;
    }
  }

  &-grey-dark {
    color: $--ui-color-black-light;

    &:hover {
      color: $--ui-color-black-light;
    }
  }
}

.input__error-hint {
  margin-top: 8px;
  color: $--ui-color-red;
}

.input__hint {
  margin-top: 8px;
  color: $--ui-color-400;
}

.inline-input__wrapper-error .inline-input {
  border-bottom-color: $--ui-color-red;
}

.replenishment-input__value-container {
  margin-left: 32px;

  .rub {

    color: $--ui-color-500;
  }

  .inline-input__wrapper {
    //width: 100%;
    margin-right: 24px;
  }

  .auth-item {
    width: auto;
    min-width: 100px;
    margin-right: 0;
  }
}

/*.bank-commission {
  margin-right: 100px;
}*/

.profile__screen,
.replenishment__screen,
.transfer__screen {
  .alert {
    margin-top: 16px;
  }

  .replenishment__warning {
    max-width: 550px;
  }

  .replenishment__button {
    //
    margin-right: 16px;
  }

  .cancel__button {
    //
  }
}

.tab__container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  .tab__item {
    padding: 10px 12px;
    border-bottom: 2px solid transparent;

    a {
      color: $--ui-color-gray-dark;
    }


    &.tab__active {
      border-bottom: 2px solid $--ui-color-blue;

      a {
        color: $--ui-color-blue;
      }
    }
  }
}

.profile__screen,
.transfer__screen {
  .transfer__inputs {
    div {
      padding: 0 12px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    /*    .transfer-value {
          max-width: 224px;
        }*/

    /*.wallet-info {
      flex: 2;
    }*/
  }
}

.alert-container {
  .alert {
    margin-top: 16px;

    &:last-child {
      margin-bottom: 8px;
    }

    &:first-child {
      margin: 24px 0 0 0;
    }
  }

  .main__screen {
    color: $--ui-color-500;

  }

  .card__container {
    .notification__button {
      margin-right: 24px;
    }

  }


  .add-company__button {
    max-width: 215px;
    margin-right: 16px;
  }

  .check-inn__button {
    max-width: 180px;
    margin-right: 16px;
  }

  .cancel__button {
    max-width: 110px;
  }
}

.add-company__button, .check-inn__button {
  margin-right: 16px
}

.radio-input__wrapper {
  transition: all .3s;

  .radio-input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .radio_icon {
    margin-right: 8px;
  }

  & .radio-input + .radio_icon {
    background: url(svg-encode($radio-inactive)) no-repeat center center;
  }

  & .radio-input:checked + .radio_icon {
    background: url(svg-encode($radio-active)) no-repeat center center;
  }
}

.radio__container {
  .radio-input__wrapper {
    margin: 0 16px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.switch__container {
  border: 0.5px solid $--ui-color-gray;
  border-radius: 8px;

  &.shop {
    width: max-content;
  }

  .switch__item {
    cursor: pointer;

    &.switch__item-active {
      background: $--ui-color-blue;
      border-radius: 8px;
    }

    padding: 4px 16px;
  }
}

.overall-amount__wrapper + .auth-item {
  width: 200px;
}

.operations-type {
  margin: 0 auto 16px 60px;
}

//------------------------------
//  Accordion restyle
//------------------------------

.accordion-button:not(.collapsed)::after,
.accordion-button::after {
  background: none;
}

.accordion-item {
  border: none;
}

.accordion-button:not(.collapsed),
.accordion-button {
  padding: 0;
  color: $--ui-color-blue;
  background: none;
  box-shadow: none;
}

.accordion-body {
  padding: 0;
}

//------------------------------
//  Props card
//------------------------------
.props-card {
  background: rgba(230, 233, 240, 0.35);
  padding: 24px;
  border-radius: 16px;
}

.props-card__header {
  margin-bottom: 8px;

  .icon__wrapper.copy, .icon__wrapper.copy-card {
    margin-left: 16px;
    background: url(svg-encode($copy_square)) no-repeat center center;
  }
}

.props-item {
  margin-bottom: 4px;

  p:last-child {
    color: $--ui-color-blue;
    text-decoration: underline;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

//------------------------------
//  Bid Accordion
//------------------------------
.accordion-button {
  &.collapsed {
    & .collapse-icon {
      background: url(svg-encode($collapse_blue_up)) no-repeat center center;
      transform: rotate(180deg)
    }
  }

  & .collapse-icon {
    margin-left: 8px;
    transition: all .3s;
    background: url(svg-encode($collapse_blue_up)) no-repeat center center;
  }
}

.blur-modal__bid {
  .input-row-item {
    flex: 2;

    &.wallet-info {
      flex: 3;
    }
  }

  .button__container {
    min-width: 324px;
  }
}

.input-row {
  .input-row-item {
    margin: 0 12px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.desc-item {
  .desc-value {
    padding: 8px 0;
  }
}

.button__container {
  button {
    margin-right: 16px;
  }

  .cancel__button {
    margin-right: 0;
  }
}

.blur-modal__sms-confirm {
  width: 448px;
  padding-top: 80px;

  .label__default {
    @extend %bold-heading;
    font-size: 11px;
    line-height: 13px;
    color: $--ui-color-300;
    letter-spacing: .05em;
    text-transform: uppercase;
    width: 100%;
  }

  .input__default {
    @extend %text-regular;
    padding: 6px 12px;
    border: 1px solid $--ui-color-gray;
    border-radius: 8px;
    margin-top: 12px;
    height: 40px;
    font-size: 15px;
    line-height: 24px;
    width: 100%;
    //max-width: 340px;
    transition: all .3s;
  }

  & :focus-visible {
    outline: none;
  }

  .input {
    &__error {
      .input__default {
        border-color: $--ui-color-red;
      }

      .input__error-hint {
        @extend %text-regular;
        margin-top: 6px;
        color: $--ui-color-red;
        font-size: 0.72em;
        line-height: 154%;
      }
    }
  }
}

.blur-modal__wallet-not-found {
  .inline-input__wrapper-modal-mail {
    margin-left: 40px;
    max-width: 306px;
    width: 100%;
  }

  .btn__default-primary-rounded {
    margin-right: 16px;
  }

}

.file-uploader {
  padding: 12px 24px;
  border-radius: 40px;
  margin-right: 32px;
  border: $--ui-color-blue-light 1px solid;
}

.toast-holder {
  position: fixed;
  top: 40px;
  right: 28px;
}

.toast {
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 0;
  }

  &.toast__ {
    &success .icon__wrapper {
      background: url(svg-encode($success-icon)) no-repeat center center;
    }

    &warning .icon__wrapper {
      background: url(svg-encode($warning-icon)) no-repeat center center;
    }

    &info .icon__wrapper {
      background: url(svg-encode($info-icon)) no-repeat center center;
    }

    &error .icon__wrapper {
      background: url(svg-encode($error_toast)) no-repeat center center;
    }
  }
}

.file-name {
  background: $--ui-color-gray;
  border-radius: 8px;
  padding: 0 8px 0 16px;

  p {
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .icon__wrapper.icon__close {
    margin-left: 4px;
    background: url(svg-encode($close-black)) no-repeat center center;
  }
}

.floated-footer {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.06);
  padding: 32px 0;
  position: sticky;
  bottom: 0;
  background: white;
}

.main__screen .btn__default-text,
.profile__screen .btn__default-text {
  @extend %one-line;
}

.mass-transfer__no-wallet {
  .mass-transfer-item__no-wallet {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .full-name {
    width: 100%;
    max-width: 210px;
    @extend %one-line;
  }

  .no-wallet-email {
    width: 100%;
    max-width: 178px;
    @extend %one-line;
  }

  .no-wallet-phone {
    @extend %one-line;
    width: 100%;
    max-width: 132px;
  }

  .no-wallet-desc {
    @extend %one-line;
    width: 100%;
    max-width: 372px;
  }
}

.operations__block .mass__operation-item {
  .operation-type {
    margin-right: 8px;
  }

  &.operation__success {
    .operation-type {
      color: $--ui-color-400;
    }
  }

  &.operation__waiting {
    .operation-type {
      color: $--ui-color-400;
    }
  }

  &.operation__warning {
    .operation-type {
      color: #E59900;
    }
  }

  &.operation__danger {
    .operation-type {
      color: $--ui-color-red;
    }
  }
}

.table-status {
  &.status-wait {
    .icon__wrapper {
      background: url(svg-encode($icon-waiting));
    }
  }

  &.status-success {
    .icon__wrapper {
      background: url(svg-encode($success-icon));
    }
  }

  &.status-error {
    .icon__wrapper {
      background: url(svg-encode($error-icon));
    }
  }
}

@import "components/mass/table";

@import "components/mass/index";

.container {
  min-width: 960px !important;
}

.btn__default-primary-rounded, .btn__default-light-rounded {
  @extend %one-line;

  .btn__default-text {
    font-size: 15px;
    line-height: 24px;
  }
}

.main-button-wrapper {
  .btn__default-light-rounded {
    margin-right: 16px;
    cursor: pointer;
    @extend %one-line;
  }

  .btn__default-primary-rounded {
    @extend %one-line;
  }

  .btn__default-text {
    width: 100%;
    max-width: 100%;
  }
}

.shop-short-name__wrapper, .full-name__wrapper {
  padding-right: 12px;
}

.shop-full-name__wrapper, .tel__wrapper {
  padding-left: 12px;
}

.short-1-line {
  @extend %one-line;
}

.alert .body-15-bold {
  @extend %bold-heading;
}

.acquiring-header {
  padding: 0 60px;
}

.acquiring-shops {
  padding: 6px 12px;
  border-radius: 8px;
  border: 1px solid $--ui-color-gray-light;
}

@import "components/footer";

.telegram-icon .icon__wrapper {
  background: url(svg-encode($telegram_icon)) no-repeat center center;
}

.chat-icon .icon__wrapper {
  background: url(svg-encode($chat-alt)) no-repeat center center;
}


@import "components/acquiring/dropdown";

.acquiring-average {
  margin-right: 24px;
}

@import "components/card";

.blur-portal__cancel_acquiring {
  .cancel__button {
    width: auto;
  }
}


.checkbox__wrapper .icon__wrapper {
  margin-right: 10px;
  background: url(svg-encode($--ui-uncheck-square-disabled));

}

.checkbox_input:checked + .icon__wrapper {
  background: url(svg-encode($--ui-check-square-active));
}

//-----------------------------------
//    Acquiring Operations Table
//-----------------------------------

.acquiring-operations-table {
  padding: 0 60px 60px 60px;

  .table-row {
    height: 56px;
    border-bottom: 1px solid rgba(167, 193, 235, 0.3);
    cursor: pointer;
    transition: all .1s;

    &.status-error {
      background: rgba(253, 229, 236, .3);
    }

    &:hover {
      background: rgba(230, 233, 240, 0.35);

      /*.table-placeholder .icon__wrapper {
        background: url(svg-encode($right-arrow)) no-repeat center center;
      }*/
    }

    &.table-header:hover {
      background: none;
    }

    .table {
      &-id {
        max-width: 130px;
        width: 100%;
      }

      &-number {
        max-width: 165px;
        width: 100%;
      }

      &-summary {
        max-width: 152px;
        width: 100%;
      }

      &-commission {
        max-width: 174px;
        width: 100%;
      }

      &-date-time {
        max-width: 152px;
        width: 100%;
      }

      &-comment {
        max-width: 254px;
        width: 100%;
        padding-right: 10px;
      }

      &-status {
        max-width: 69px;
        width: 100%;
      }

      &-placeholder {
        max-width: 56px;
        width: 100%;
      }

      &-spacer {
        max-width: 24px;
        width: 100%;
      }

      &-item {
        overflow: hidden;

        p {
          @extend %one-line
        }

        &.table-date-time p {
          -webkit-line-clamp: 2;
          white-space: normal;
        }
      }
    }
  }
}

.blur-modal__transfer_acquiring {
  .transfer-commission {
    margin-left: 32px;
  }
}

.blur-modal__acquiring_info {
  .status-badge {
    min-width: 152px;
    padding: 0 12px;
    border-radius: 16px;
    margin-left: 16px;
  }

  .acquiring-info-summary,
  .acquiring-info-commission {
    margin-right: 16px;
  }
}


.blur-modal__transfer_acquiring_ver2 {
  .input-commission-container {
    .inline-input__wrapper {
      //width: 100%;
      margin-right: 32px;

      &:last-child {
        min-width: 190px;
        overflow: hidden;
      }
    }
  }
}

.badge-container {
  .badge {
    padding: 0 12px;
    border-radius: 8px;
    margin-right: 8px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }
}

//------------------------------------------
//                Landing
//------------------------------------------
body.adaptive {
  min-width: 320px !important;

  .container,
  .blur-portal {
    min-width: 320px !important;
  }

  .blur-modal__sms-confirm .input__wrapper {
    width: 100%;
  }

  .code-input {
    max-width: 100%;
    min-width: 100%;
  }
}

.shop {
  .logo-container {
    margin-top: 64px;

    .logo-wrapper {
      height: 40px;
      width: 52px;
      margin-right: 14px;
    }
  }

  .button-secondary-rounded {
    background: #EEF4FF;
    padding: 12px 24px;
    border-radius: 40px;
  }

  .shop-body {
    margin-top: 48px;
    margin-bottom: 80px;
    border-radius: 24px;
    box-shadow: 0 15px 50px 0 #5086E033;

    .left-section,
    .right-section {
      border-radius: 24px;
      padding: 48px;
    }

    .right-section-card {
      border-radius: 24px;
      padding: 48px 0 0 0;

      .register-container {
        background: linear-gradient(278.78deg, #C3D9FC -43.53%, #E9F0FD 73.33%, #88ADF6 139.6%);
        border-radius: 16px;
        padding: 32px;
        width: 100%
      }
    }

    .left-section {
      background: #F5F7FA;
    }

    .shop-heading {
      margin-bottom: 20px;
    }

    .purchase-caption {
      margin-bottom: 8px;
    }

    .purchase-item {
      margin-top: 16px;
    }

    .dashed-line {
      border-top: 1px dashed #D4D9E2;
      flex: 1;
      height: 0;
      margin: auto 16px;
    }

    .purchase-container {
      padding-bottom: 40px;
      //overflow-y: scroll;
      border-bottom: 1px solid #D4D9E2;
    }

    .visibility-toggle {
      cursor: pointer;
    }

    .icon-hide {
      margin-left: 10px;
      background: url(svg-encode($--ui-view-hide)) no-repeat center center;
    }

    .icon-show {
      margin-left: 10px;
      background: url(svg-encode($--ui-view-show)) no-repeat center center;
    }

    .button-shop {
      background-color: $--ui-color-blue;
      padding: 12px 24px;
      border-radius: 40px;
    }
  }

  footer {
    margin-top: auto;
    padding-bottom: 80px;

    .footer-icon-container {
      margin-bottom: 18px;

      .pci-icon {
        width: 58px;
        height: 22px;
        margin-right: 21px;
      }

      .visa-secure-icon {
        width: 25px;
        height: 26px;
        margin-right: 21px;
      }

      .mastercard-icon {
        width: 26px;
        height: 26px;
        margin-right: 21px;
      }

      .mir-icon {
        width: 43px;
        height: 20px;

      }
    }
  }
}


.user__wrapper {
  max-width: 400px;

  .text-2-bold {
    @extend %one-line;
    white-space: normal;
  }
}

.one-line {
  @extend %one-line;
  width: 100%;
  white-space: normal;
}

.notification-icon {
  background: url(svg-encode($notification-item-no-badge)) no-repeat center center;
}

.active-badge {
  background: url(svg-encode($notification-item)) no-repeat center center;
}


.header_v2 {

  .user-menu__wrapper {

    .user-badge {
      display: flex;
      justify-content: end;
      position: relative;
      top: 12px;
      right: 12px;
      height: 10px;

      img {
        width: 10px;
        height: 10px;
      }
    }

  }

  .dropdown_notification {
    min-width: 440px;
    padding: 40px 0;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.12);
    border-radius: 24px;

    .notification-dropdown-header {
      margin-left: 40px;
      margin-bottom: 24px;

      p {
        text-align: left;
        font-size: 1.11em;
        line-height: 1.55em;
      }
    }

    .notification-item {
      padding: 8px 40px;


      &.notification-content {
        padding: 0 40px;


      }

      .red-circle {
        width: 8px;
        height: 8px;
        background-color: $--ui-color-red;
        border-radius: 50%;
        margin-right: 12px;
      }


      .date-text {
        @extend %bold-heading;
        font-size: 0.56em;
        line-height: 0.72em;
        color: $--ui-color-grey-med;
        text-transform: uppercase;
        /*text-align: left;*/
      }
    }

    .notification-item:hover {
      background-color: #F5F7FA;
    }

    .horizontal-divider-notification {
      margin: 20px 40px;
      border-bottom: 0.5px solid $--ui-color-gray;
    }

    .horizontal-divider-notification:last-child {
      margin: 0;
      border: none;
    }
  }


}

.dropdown_header__wrapper {
  padding: 12px 0;
  max-width: 190px;
  min-width: 190px;
  transition: .3s all ease-in-out;
  border-radius: 8px;
  box-shadow: 0 4px 60px rgba(0, 0, 0, 0.08);

  &.wallet-expanded {
    max-width: 350px;
    min-width: 350px;
  }
}

.content__wrapper {
  padding: 5px 12px 5px 16px;
}

.button-plan__container .gold {

  background: url(svg-encode($ui-vidi-logo-plan-gold)) no-repeat right 100%, linear-gradient(90deg, #FFC594 -2.38%, #FFCE85 35.09%, #FEA050 85.98%);
}

.button-plan__container .gold:hover {
  background: url(svg-encode($ui-vidi-logo-plan-gold)) no-repeat right 100%, linear-gradient(0deg, rgba(232, 127, 0, 0.68), rgba(232, 127, 0, 0.68)), linear-gradient(90deg, #FFC594 -2.38%, #FFCE85 35.09%, #FEA050 85.98%);
}

.button-plan__container .silver {

  background: url(svg-encode($ui-vidi-logo-plan-silver)) no-repeat right 100%, #EEF4FF;;
}

.button-plan__container .silver:hover {
  background: url(svg-encode($ui-vidi-logo-plan-silver)) no-repeat right 100%, linear-gradient(0deg, rgba(172, 195, 237, 0.5), rgba(172, 195, 237, 0.5)), #EEF4FF;
}

.button-dropdown_wrapper {
  padding: 10px 16px 10px 16px;
  justify-content: space-between;
  display: flex;
  &.silver:hover {
    background: linear-gradient(0deg, rgba(172, 195, 237, 0.5), rgba(172, 195, 237, 0.5)), #EEF4FF;
  }

  &.gold:hover {
    background: linear-gradient(0deg, rgba(232, 127, 0, 0.68), rgba(232, 127, 0, 0.68)), linear-gradient(90deg, #FFC594 -2.38%, #FFCE85 35.09%, #FEA050 85.98%);
  }

  .button-text__dropdown {
    color: $--ui-color-450;
  }

  .right-icon__wrapper {
    width: 16px;
    height: 16px;
  }
}

.hr-dropdown {
  margin: 12px 8px;
  border: 1px solid $--ui-color-gray;
}

.dropdown-text__wrapper {
  display: contents;

  &.more-text {
    @extend %text-regular;
    color: $--ui-color-300;
    font-size: 0.72em;
    line-height: 154%;
  }

}

.header_v2 .user-menu__wrapper .icon__wrapper {
  margin-right: 0;
}

.error-500 {
  background: url(svg-encode($status-500)) no-repeat center center;
  min-height: 100vh;
}


@media (max-width: 1139px) {
  .card__wrapper {
    max-width: 50%;

    .card__item .card__header .wallet {
      margin-right: 16px;
    }
  }
  .main-button-wrapper .btn__default-light-rounded {
    margin-right: 0;
    margin-bottom: 16px;
  }
}

// tariff page
.scroll-btn-wrapper {
  border: 0.5px solid $--ui-color-gray;
  border-radius: 8px;
  padding: 6px;

  &:first-child {
    margin-right: 24px;
  }
}

.scroll-left .icon__wrapper {
  background: url(svg-encode($icon-page)) no-repeat center center;
}

.scroll-left.active .icon__wrapper {
  background: url(svg-encode($icon-page-active)) no-repeat center center;
}

.scroll-right .icon__wrapper {
  background: url(svg-encode($icon-page)) no-repeat center center;
}

.scroll-right.active .icon__wrapper {
  background: url(svg-encode($icon-page-active)) no-repeat center center;
}

.tariff-scroller {

  display: flex;
  overflow-x: hidden;

  .tariff-card {

    min-width: 444px;
    max-width: 444px;
    min-height: 566px;
    margin-right: 24px;
  }

  .tariff-disabled {
    background: $--ui-color-inversive-400;
    opacity: 0.4;
  }

  .tariff-card:last-child {
    margin-right: 0;
  }

}

/* Tariff card */
@import "components/tariff_card";
/* End Tariff card */

/* Tariff modal*/

@import 'components/tariff_modal';

.tariff-card-icon .icon__wrapper {
  background: url(svg-encode($tariff-card)) no-repeat center center;
}

.tariff-wallet-icon .icon__wrapper {
  background: url(svg-encode($tariff-wallet)) no-repeat center center;
}

.tariff-shop-icon .icon__wrapper {
  background: url(svg-encode($tariff-wallet)) no-repeat center center;
}

.tariff-warning-icon .icon__wrapper {
  background: url(svg-encode($warning-icon)) no-repeat center center;
}

.income-icon .icon__wrapper {
  background: url(svg-encode($income-icon)) no-repeat center center;
}

/*End tariff modal*/

.table-tariff {

  thead {
    tr {
      th {
        padding: 20px 0 16px 12px;
      }
    }
  }

  tbody {
    tr {
      th {
        padding: 20px 0 16px 12px;
      }

      td {
        padding: 18px 0 14px 12px;
      }
    }
  }

  tr {
    border-bottom: 1px solid $--ui-color-blue-med-light;
  }

  .description-column {
    width: 368px;
  }

  .status-column {
    width: 232px;
  }

  .tariff-column {
    width: 232px;
    background-color: $--ui-color-blue-light;
  }

}

.blur-modal__send_referral-modal {

  .left-section {
    width: 472px;
    background: #EEF4FF;
    border-radius: 16px;
    padding: 24px;

    .referral-quest-card {
      margin-top: 24px;
      background-color: $--ui-color-inversive-500;
      border-radius: 30px;
      padding: 32px;

      .referral-quest-description {
        color: $--ui-color-black-light;
        max-width: 218px;
      }

      .referral-quest-prize {
        border-radius: 16px;
        padding: 16px;
        background-color: rgba(233, 240, 253, 0.3);

        .referral-amount-prize {
          text-align: right;
        }

      }

      .additional-terms {
        margin-top: 8px;
        font-style: normal;
        font-weight: 500;
        font-size: 0.61em;
        line-height: 1.18em;
        color: $--ui-color-black-light;
      }

    }
  }

  .right-section {
    margin-left: 32px;
    width: 386px;

    .container_invite-link {
      &.hide {
        display: none;
      }

      .circle_icon-referral {
        width: 56px;
        height: 56px;
        border-radius: 50%;

        &.copy {
          background: url(svg-encode($copy-icon)) no-repeat center, $--ui-color-grey-med;
        }

        &.sms {
          background: url(svg-encode($message-icon-btn)) no-repeat center, $--ui-color-grey-med;

          &.active {
            border: 2px solid $--ui-color-blue-med;
          }
        }

      }

      .button_referral__container {

        p {
          margin-top: 6px;
          font-style: normal;
          font-weight: 500;
          font-size: 0.61em;
          line-height: 1.18em;
        }
      }

      .phone-input__container {
        &.hide {
          display: none;
        }
      }

    }

    .container_invite-email {
      &.hide {
        display: none;
      }
    }

  }

}


.status-card-profile-settings {
  background: url(svg-encode($ui-vidi-logo-background-status-card)) no-repeat 83% 100%, #EEF4FF;;
  border-radius: 30px;
  padding: 15px 24px 15px 18px;

  &.hide {
    display: none !important;

  }
}

.status-section {

  .tariff-logo {
    width: 80px;
    height: 80px;
    margin-right: 16px;
  }

}

.btn-group-profile-settings {

  a {
    color: $--ui-color-grey-med;
    padding: 8px 12px;

    &:hover {
      color: $--ui-color-blue;
    }

    &.active {
      color: $--ui-color-blue;
      border-bottom: 1px solid $--ui-color-blue;
    }
  }

}

.profile-container {

  &.hide {
    display: none;
  }

  .step-circle {
    width: 32px;
    height: 32px;
    background-color: $--ui-color-gray-light;
    border-radius: 50%;
    margin-right: 8px;
  }

  .steps-container {

    .step-item.first {

      .step-circle {
        background: url(svg-encode($number-1-active-icon)) no-repeat center center, $--ui-color-blue;
      }

      &.success {
        .step-circle {
          background: url(svg-encode($done-white-icon)) no-repeat center center, $--ui-color-blue;
        }

      }

    }

    .step-item.two {

      .step-circle {
        background: url(svg-encode($number-2-icon)) no-repeat center center, $--ui-color-gray-light;
      }

      &.success {
        .step-circle {
          background: url(svg-encode($done-white-icon)) no-repeat center center, $--ui-color-blue;
        }

        p {
          color: $--ui-color-500;
        }
      }

      &.current {
        .step-circle {
          background: url(svg-encode($number-2-active-icon)) no-repeat center center, $--ui-color-blue;
        }

        p {
          color: $--ui-color-500;
        }

      }

    }

    .step-item.three {

      .step-circle {
        background: url(svg-encode($number-3-icon)) no-repeat center center, $--ui-color-gray-light
      }

      &.success {
        .step-circle {
          background: url(svg-encode($done-white-icon)) no-repeat center center, $--ui-color-blue;
        }

        p {
          color: $--ui-color-500;
        }

      }

      &.current {
        .step-circle {
          background: url(svg-encode($number-3-active-icon)) no-repeat center center, $--ui-color-blue;
        }

        p {
          color: $--ui-color-500;
        }

      }

    }

    .step-divider {
      width: 69.5px;
      margin: 0 16px;
    }

  }

  .parameters-auth {

    .auth-parameters {

      .auth-item:last-child {
        margin-left: 24px;
      }

    }



  }

  .one-step.hide {
    display: none;
  }

  .two-step.hide {
    display: none;
  }

  .three-step.hide {
    display: none;
  }

  .right-section {

    .invite-friend {
      width: 416px;
      padding: 32px;
      background: linear-gradient(99.66deg, #F6DBF6 -1.38%, #D4CDFF 96.58%);
      border-radius: 30px;
    }

    .news {
      width: 416px;
      height: 180px;
      padding: 32px;
      background: linear-gradient(277.85deg, #FDF5CC -2.99%, #FFBA93 99.01%);
      border-radius: 30px;
    }

  }

}

.subscribe-container {
  &.hide {
    display: none;
  }

  .container_bank-card.hide {
    display: none;
  }

  .subscribe-profile__button {
    margin-right: 16px;
  }

}

.documents-container {
  &.hide {
    display: none;
  }

  .download-documents_container {
    border-bottom: 1px solid $--ui-color-blue-med-light;
    padding: 18px 19px 17px 12px;
  }
}


.accordion-download-docs {
  .accordion-header {

    .icon_dropdown-download {
      width: 12px;
      height: 12px;
      margin-right: 30px;
      background: url(svg-encode($ui-arrow-blue-down)) no-repeat center center;

    }

    &.active {
      background-color: $--ui-color-inversive-400;

      .icon_dropdown-download {
        transform: rotate(180deg);
      }
    }

  }

  .accordion-body {

    .download-documents_container {
      padding-left: 55px;
    }
  }

}

.blur-modal__cancel_subsciption {

  .btn__default-light-rounded {
    margin-left: 16px;
  }
}

.acquiring-income {
  padding-left: 24px;
}

.settings .modal__loader-enabled {
  position: fixed;
}

.modal__wrapper-shop {

  .input__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: 340px;
    position: relative;
  }

  .label__default {
    font-size: 11px;
    line-height: 13px;
    color: #707a83;
    letter-spacing: .05em;
    text-transform: uppercase;
    width: 100%;
  }

  .input__default {
    @extend %text-regular;
    padding: 6px 12px;
    border: 1px solid #d4d9e2;
    border-radius: 8px;
    margin-top: 12px;
    height: 40px;
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    max-width: 340px;
    transition: all .3s;
  }

  .input__default:focus {
    outline: none
  }
}

.card__wrapper-tariff {
  width: 196px;
  transition: 0.4s max-width;
  position: relative;

  .badge-card__item-tariff {
    position: absolute;
    top: -8px;
    right: -14px;
    background: #FF7575;
    border-radius: 24px;
    width: 70px;
    min-height: 40px;
  }

  .card__item-tariff {
    max-height: 116px;
    //height: 185px;
    padding: 24px 30px;
    background-color: $--ui-color-blue-light;
    border-radius: 30px;
    flex: 1;
    border: 2px solid $--ui-color-blue-light;
  }

  .card__active-tariff {
    border: 2px solid $--ui-color-blue-med;
  }

}

.hr-warning {
  margin: 8px 0;
  background: #E59900;
  height: 1px;
  opacity: 1
}

.warning-text-color {
  color: #E59900
}

@include media-breakpoint-down(md) {
  body {
    min-width: 100% !important;
  }

  .footer {
    min-width: 100vw !important;
  }

  .modal__wrapper {
    max-width: 95vw;
    padding: 50px;
    margin: auto;
  }


  .auth__screen {
    min-width: 100%;

    .container {
      min-width: 100% !important;
    }

    .input__wrapper {
      width: 100%;
    }
  }

  .profile__screen {

    .header__wrapper {
      width: 1024px;
    }

    .blur-portal__sms-confirm.active {
      width: 100vw !important;
      display: block;
      margin-top: auto;
      margin-block: auto;

      .blur-modal__sms-confirm {
        width: 90vw;
        margin: 35vw 0 auto 5vw !important;

        .h2 {
          font-size: 1.17em;
        }
      }
    }

  }
}

@import "components/colors";
@import "components/account-selector";
@import "components/qr_wrapper";
@import "components/exchanges_block";
@import "components/helper";
@import "components/external-add";
@import "components/switch";
@import "components/vid_modal";
@import "components/dropdown";
@import "components/vidi-dropdown";


.input-icons {

  position: relative;

  .input-field {
    outline: none;
    border: none;
    width: 100%
  }

  .description-input {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 12%);
    border-radius: 8px;
    padding: 8px;
    width: 241px;
    margin-left: auto;
    margin-right: 0;
    position: absolute;
    top: -70px;
    right: 0px;
  }
}

.usdt-modal {
  padding: 24px;
  background: #f5f7fa;
  border-radius: 12px;
}

.popup-grey {
  background: #D4D9E2;
  border: 1px solid #E6E9F0;
  border-radius: 8px;
  padding: 6px 12px;
  position: absolute;
  z-index: 99999;
  opacity: 1;

  &.d-none {
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }
}

@import "components/block-request";

.uploaded-file {
  background: #D4D9E2;
  border-radius: 8px;
  padding: 0 16px;

  p {
    margin-right: 4px;
  }
}

.container-fluid {
  overflow-y: scroll;
}

.header__credits {
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 40px;
  -webkit-line-clamp: 2;
  max-width: 200px;
  -webkit-box-orient: vertical;
  text-align: right;
}

.red-circle-10 {
  width: 10px;
  height: 10px;
  background-color: $--ui-color-red;
  border-radius: 50%;
}

.modal__loader_description {
  display: flex;
  flex-direction: column;
  padding: 24px 30px; 
  background: #FFFFFF;
  border-radius: 24px;
  align-items: center;
  justify-content: center;

}