.switch {
  border: 0.5px solid #D4D9E2;
  border-radius: 8px;
  position: relative;
  .switch__item {
    z-index: 2;
    font-family: "Montserrat",sans-serif,sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #707A83;
    padding: 4px 16px;
    cursor: pointer;
    transition: 0.3s color;
    &.switch__item-active {
      font-weight: 600;
      color: #4482FF;
    }

    &.no-wrap {
      white-space: nowrap;
    }
  }
  .switch__bg {
    transition: 0.3s all;
    z-index: 1;
    position: absolute;
    right: 50%;
    left: 0;
    top: 0;
    bottom: 0;
    background: #EEF4FF;
    border-radius: 8px;

    &.switch__bg-100 {
      right: 0;
    }

    &.left-bg__shop {
      left: 0;
      right: 44.0162%;
    }

    &.center-bg__shop-three-elem {
      left: 38.7727%;
      right: 28.0909%;
    }

        &.left-bg__shop-three-elem {
          left: 0px;
          right: 59.6818%;
        }

    &.right-bg__shop {
      left: 55.0897%;
      right: 0;
    }

  }
}